.auth-block{
    margin:0 auto;
    background: #fff;
    border-radius: 10px;
    width: 95%;

    -webkit-box-shadow: 10px 10px 15px -12px rgba(5,5,5,1);
    -moz-box-shadow: 10px 10px 15px -12px rgba(5,5,5,1);
    box-shadow: 10px 10px 15px -12px rgba(5,5,5,1);
}

@media (min-width: 576px) {  
    .auth-block {
        width: 80%;
    }
}
@media (min-width: 1200px) { 
    .auth-block {
        width: 50%;
    }
 }

.auth-block h3{
    text-align: center;
    padding-top: 20px;
    margin-bottom: 30px;
}
.auth-block h4{
    text-align: center;
    padding-top: 15px;
    margin-bottom: 15px;
}

.auth-block form{
    padding: 5px 20px 20px 20px;
}

.auth-block .auth-footer{
    background: #e8fced;
    padding: 20px;
    border-bottom-left-radius:10px;
    border-bottom-right-radius: 10px;
}
.accept{ 
      padding: 40px 20px 20px 20px;    
} 
  
div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
.MuiStepIcon-root.MuiStepIcon-active {
    color: #6fa3d4;
}
 .MuiStepIcon-active {
    color: #ff4800;
}