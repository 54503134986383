
@font-face {
    font-family: "BPG Arial";
    src: url(../../static/fonts/bpg_arial.ttf);
    font-weight: 400;
  }
  
  body{
    margin: 0;
    padding: 0;
    font-family: BPG Arial;
    font-size: 13px;
    background: #f2f2f2;
    background-color: #f2f2f2;
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .swal2-header{
    font-size: 9px!important
  }
  
  .swal2-actions button {
    font-size: 12px!important
  }
  
  .form-group{
    position: relative;
  }
  
  .necessey-field{
    font-size: 20px;
    color: red;
    top: 35px;
    left:-10px;
    position: absolute;
    font-weight: bold;
  }
  
  .necessey-field-text{
    position: relative;
    padding-left: 12px;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .necessey-field-text span{
    position: absolute;
    font-size: 20px;
    color: red;
    left:0px
  }
   
  
  .square-card {
    cursor: pointer;
    height: 200px;
    justify-content: center;
    align-items: center;
    border: 1px solid #06aa6b;
    font-size: 24px;  
  }
  
  .square-card-disabled {
    height: 200px;
    justify-content: center;
    align-items: center;
    border: 1px solid #434444;
    font-size: 24px;
    color:grey;
    background-color: rgb(182, 182, 182);
    
  }
  
  
  