.header {
    justify-content: space-between;
    /* background-color: #ebeff3; */
    background-color: #6fa3d4;
    margin-bottom: 20px;
    padding: 15px;
  }
  
  .logo img {
    width: 150px;
  }
  
  .logo span {
    color: rgb(209, 20, 20);
    width: 200px;
    font-size: 12px;
    position: absolute;
    margin-left: 10px;
    margin-top: -3px;
  }
  
  .header-title {
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
  }
  .navbar-custom {
    background-color: rgb(133, 141, 133);
}
   